import React, { useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import "./Projects.scss";

const Projects = () => {
  const projectsRef = useRef(null);
  const previewRef = useRef(null);
  const previewImgRef = useRef(null);

  useLayoutEffect(() => {
    const projects = projectsRef.current;
    const preview = previewRef.current;
    const previewImg = previewImgRef.current;

    let isInside = false;

    const bgPositions = {
      p1: "0 0",
      p2: "0 25%",
      p3: "0 50%",
      p4: "0 75%",
      p5: "0 100%",
    };

    const moveStuff = (e) => {
      const mouseInside = isMouseInsideContainer(e);

      if (mouseInside !== isInside) {
        isInside = mouseInside;

        if (isInside) {
          gsap.to(preview, { duration: 0.3, scale: 1 });
        } else {
          gsap.to(preview, { duration: 0.3, scale: 0, delay: 0.1 });
        }
      }

      moveProject(e);
    };

    const moveProject = (e) => {
      const containerRect = projects.getBoundingClientRect();
      const offsetX = (e.clientX - containerRect.left) * 0.5;
      const offsetY = (e.clientY - containerRect.top) * 0.25;

      gsap.to(preview, { duration: 0.3, x: offsetX, y: offsetY });
    };

    const moveProjectImg = (project) => {
      const projectId = project.id;
      gsap.to(previewImg, {
        duration: 0.4,
        backgroundPosition: bgPositions[projectId] || "0 0",
      });
    };

    const isMouseInsideContainer = (e) => {
      const containerRect = projects.getBoundingClientRect();
      const scrollY = window.scrollY || window.pageYOffset;
      const scrollX = window.scrollX || window.pageXOffset;

      return (
        e.pageX >= containerRect.left + scrollX &&
        e.pageX <= containerRect.right + scrollX &&
        e.pageY >= containerRect.top + scrollY &&
        e.pageY <= containerRect.bottom + scrollY
      );
    };

    window.addEventListener("mousemove", moveStuff);

    Array.from(projects.children).forEach((project) => {
      project.addEventListener("mouseenter", () => moveProjectImg(project));
    });

    // Clean up event listeners when component unmounts
    return () => {
      window.removeEventListener("mousemove", moveStuff);
      Array.from(projects.children).forEach((project) => {
        project.removeEventListener("mouseenter", () =>
          moveProjectImg(project)
        );
      });
    };
  }, []);

  return (
    //https://option5.studio/ might take more inspo here
    <section className="app__projects" id="projects">
      <div className="projects-headline">
        <h1 className="selected-projects">
          <a
            className="index-button mx-hide"
            href="https://dribbble.com/liammelkersson"
          >
            <p>Project Index</p>
          </a>
          SELECTED <br />
          PROJECTS
        </h1>
      </div>
      <div className="preview" ref={previewRef}>
        <div className="preview-img" ref={previewImgRef}></div>
      </div>
      <div className="container">
        <div className="projects" ref={projectsRef}>
          <a className="project" id="p1" href="/01">
            <div className="project-name">
              <p>Stammy</p>
            </div>
            <div className="project-category">
              <p>Website</p>
            </div>
            <div className="project-year">
              <p>2024</p>
            </div>
          </a>
          <a className="project" id="p2" href="/02">
            <div className="project-name">
              <p>OneOneOne™</p>
            </div>
            <div className="project-category">
              <p>Branding</p>
            </div>
            <div className="project-year">
              <p>2023</p>
            </div>
          </a>
          <a className="project" id="p3" href="/03">
            <div className="project-name">
              <p>Portfolio</p>
            </div>
            <div className="project-category">
              <p>REST API</p>
            </div>
            <div className="project-year">
              <p>2023</p>
            </div>
          </a>
          <a className="project" id="p4" href="/04">
            <div className="project-name">
              <p>Soundscape™</p>
            </div>
            <div className="project-category">
              <p>Magazine</p>
            </div>
            <div className="project-year">
              <p>2023</p>
            </div>
          </a>
          <a className="project" id="p5" href="/05">
            <div className="project-name">
              <p>Junya Cosmetics™</p>
            </div>
            <div className="project-category">
              <p>Branding</p>
            </div>
            <div className="project-year">
              <p>2022</p>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Projects;
