import React from "react";
import { Navbar, Banner } from "../../components";
import { Contact } from "../../containers";

// import "./Project02.scss";
import images from "../../constants/images";
import Preloader2 from "../../components/PreLoader2/Preloader2";

const Project02 = () => {
  return (
    <div className="app__project01">
      <Preloader2 />
      <Banner />
      <Navbar />

      <h4 className="phone-h4 h4 mx-hide">JUNYA COSMETICS™</h4>
      <div className="phone-proj-header hide">
        <h4 className="h4 hide">JUNYA COSMETICS™</h4>
      </div>
      <img src={images.skin} alt="" className="proj-thumbnail" />

      <p className="brief proj-text mx-hide">
        <b>CREATIVE BRIEF</b> Logotype come up with a brand name and create a
        logotype for one of the companies presented further down on this page.
        You should hand in your logotype in vector format as four separate
        illustrator EPS-Files. One for each colour reference: CMYK, RGB, Pantone
        and B/W.
      </p>
      <p className="brief phone-proj-text hide">
        <b>CREATIVE BRIEF</b> Logotype come up with a brand name and create a
        logotype for one of the companies presented further down on this page.
        You should hand in your logotype in vector format as four separate
        illustrator EPS-Files. One for each colour reference: CMYK, RGB, Pantone
        and B/W.
      </p>

      <div className="showcase-container">
        <img
          src={images.visualidentity}
          className="proj-media"
          alt="junya"
        ></img>
        <img src={images.junya} className="proj-media" alt="junya"></img>
      </div>

      <p className="proj-text mx-hide">
        <b>COMPANY BRIEF</b> Two cousins from the suburbs of southern Stockholm
        have combined their love for high-end quality cosmetics and sustainable
        living into a line products with an intended launch unto the market in
        2023. The product line will eventually contain everything from quality
        skincare products to high fashion make-up and make-up tools. First they
        need help with coming up with a solid brand name, a logotype and a
        visual identity for the company. This is a high-end products in a fairly
        high price range, and the visual identity needs to reflect that.
      </p>
      <p className="phone-proj-text hide">
        <b>COMPANY BRIEF</b> Two cousins from the suburbs of southern Stockholm
        have combined their love for high-end quality cosmetics and sustainable
        living into a line products with an intended launch unto the market in
        2023. The product line will eventually contain everything from quality
        skincare products to high fashion make-up and make-up tools. First they
        need help with coming up with a solid brand name, a logotype and a
        visual identity for the company. This is a high-end products in a fairly
        high price range, and the visual identity needs to reflect that.
      </p>

      <div className="showcase-container">
        <img src={images.typography} className="proj-media" alt="junya"></img>
      </div>

      <div className="last-text-box">
        {/* <p className="proj-text">
          <b>TYPOGRAPHY + COLOR</b> In crafting this presentation, I've embraced
          the elegant strokes of Helvetica Bold (sans serif) and the refined
          character of Garamond narrow (serif). Delving into a monochromatic
          palette, I've opted for a nuanced approach, steering clear of pure
          blacks and whites. Instead, I've chosen an off-white tone for a softer
          white and a more subdued black.
        </p> */}

        <div className="proj-links">
          <div className="proj-link">
            <a href="https://dribbble.com/shots/23013860-Junya-Cosmetics-Branding">
              Dribble
            </a>{" "}
            <span className="mx-hide">01</span>
          </div>
          <div className="proj-link">
            <a href="https://drive.google.com/uc?export=download&id=1PrN7fiKSwjELhH5Lwm_tve6Imo0srFDi">
              Visual Identity
            </a>{" "}
            <span className="mx-hide">02</span>
          </div>
        </div>
      </div>

      <hr />

      <footer className="proj-footer hide">
        <a href="/#projects" className="go-back-btn">
          ← Go back
        </a>
        <p className="proj-footer-text">Liam Melkersson ©2024</p>
      </footer>

      <Contact />
    </div>
  );
};

export default Project02;
