import React from "react";
import { Navbar, Banner } from "../../components";
import { Contact } from "../../containers";

import images from "../../constants/images";
import Preloader2 from "../../components/PreLoader2/Preloader2";

const Project04 = () => {
  return (
    <div className="app__project01">
      <Preloader2 />
      <Banner />
      <Navbar />

      <h4 className="phone-h4 h4 mx-hide">SOUNDSCAPE™ MAGAZINE</h4>
      <div className="phone-proj-header hide">
        <h4 className="h4 hide">SOUNDSCAPE™ MAGAZINE</h4>
      </div>

      <img
        src={images.soundscape}
        alt="soundscape"
        className="proj-thumbnail"
      />

      <p className="brief proj-text mx-hide">
        <b>BRIEF</b> Create a magazine with at least 24 pages (we ended up with
        around 80 pages). Print it at a print shop + include Interactive digital
        PDF version.
        <br />
        Our group decided to make our magazine about Music. We liked the word
        Soundscape since it kinda sounds like "The landscape of music", which
        seemed cool to us. Then we split up the magazine by giving a part to
        each member. Then each member chose a genre to write about. Mine was Hip
        Hop. We worked collaborative in Adobe InDesign (combined with
        Photoshop).
      </p>
      <p className="brief phone-proj-text hide">
        <b>BRIEF</b> Create a magazine with at least 24 pages (we ended up with
        around 80 pages). Print it at a print shop + include Interactive digital
        PDF version.
        <br />
        Our group decided to make our magazine about Music. We liked the word
        Soundscape since it kinda sounds like "The landscape of music", which
        seemed cool to us. Then we split up the magazine by giving a part to
        each member. Then each member chose a genre to write about. Mine was Hip
        Hop. We worked collaborative in Adobe InDesign (combined with
        Photoshop).
      </p>

      <div className="showcase-container">
        <img
          src={images.andre3000}
          className="proj-media"
          alt="soundscape"
        ></img>
        <img
          src={images.virgilabloh}
          className="proj-media"
          alt="soundscape"
        ></img>
      </div>

      <p className="proj-text mx-hide">
        <b>REFLECTION</b> I'm delighted with this this project. We actually
        printed the magazine and it was so cool having my own copy of something
        I designed. The cover is also designed by me, and I'm really happy with
        that part.
      </p>
      <p className="phone-proj-text hide">
        <b>REFLECTION</b> I'm delighted with this this project. We actually
        printed the magazine and it was so cool having my own copy of something
        I designed. The cover is also designed by me, and I'm really happy with
        that part.
      </p>

      <div className="showcase-container">
        <img
          src={images.frankocean}
          className="proj-media"
          alt="soundscape"
        ></img>
        <img
          src={images.pinkmatter}
          className="proj-media"
          alt="soundscape"
        ></img>
      </div>

      <div className="last-text-box">
        {/* <p className="proj-text">
          <b>TECH STACK</b> HTML, CSS, JavaScript, Node.js, Express.js,
          Tailwind.css, Handlebars, SQLite3.
        </p> */}

        <div className="proj-links">
          <div className="proj-link">
            <a href="https://dribbble.com/shots/22954160-SOUNDSCAPE-Magazine-2023">
              Dribble
            </a>{" "}
            <span className="mx-hide">01</span>
          </div>
          <div className="proj-link">
            <a href="https://drive.google.com/uc?export=download&id=1-m8duWd8hMRbvHJ5M7ELf_P-W9d1T4WM">
              DOWNLOAD
            </a>{" "}
            <span className="mx-hide">02</span>
          </div>
        </div>
      </div>

      <hr />

      <footer className="proj-footer">
        <a href="/#projects" className="go-back-btn">
          ← Go back
        </a>
        <p className="proj-footer-text">Liam Melkersson ©2024</p>
      </footer>

      <Contact />
    </div>
  );
};

export default Project04;
