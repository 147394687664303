import React from "react";

import "./Home.scss";
import { images } from "../../constants";

import { motion } from "framer-motion";

const Home = () => {
  return (
    <section className="app__home" id="home">
      <motion.div
        initial={{ y: 0, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 0, opacity: 0 }}
        transition={{ duration: 1, delay: 4.5 }}
        className="app__header"
      >
        <div className="mx-hide">
          <h2 className="mx-hide">DESIGNER</h2>
          <h2 className="mx-hide">DEVELOPER</h2>
        </div>
        <h1 className="mx-hide">LIAM MELKERSSON</h1>

        <div className="phone-landing">
          {/* <img src={images.helmetgif} alt="" className="phone-gif hide" /> */}
          <h1 className="hide phone-h1">LIAM MELKERSSON</h1>
          <p className="phone-intro hide">Designer + Developer + Creative</p>

          <img className="hide phone-landing-img" src={images.me} alt="" />
        </div>
      </motion.div>
    </section>
  );
};

export default Home;
