import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Navbar.scss";
import { images } from "../../constants";
import PhoneMenu from "../PhoneMenu/PhoneMenu";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  // Attach the scroll event listener to the window
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isPhoneMenuOpen, setIsPhoneMenuOpen] = useState(false);

  const togglePhoneMenu = () => {
    console.log("Toggle button clicked");
    setIsPhoneMenuOpen(!isPhoneMenuOpen);
  };

  return (
    <>
      <motion.nav
        whileInView={{ y: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.65, delay: 3.5 }}
        className="app__navbar mx-hide"
      >
        <div className="app__navbar-logo">
          <a href="#home">
            <h1>
              <a href="/">LIAM MELKERSSON™ </a>
            </h1>
          </a>
          <img
            src={images.helmetgif}
            alt="gif if a helmet"
            className="helmet-gif mx-hide"
          />
        </div>
        <div className="app__navbar-text-1 p-text mx-hide">
          <p>Designer—Developer—Creative</p>
        </div>
        <div className="app__navbar-text-2 p-text mx-hide">
          <p>Based in JKPG, Sweden</p>
        </div>
        <ul className="app__navbar-links">
          {["projects", "about", "contact"].map((item) => (
            <li className="app__navbar-link mx-hide" key={`link-${item}`}>
              <div />
              <a href={`/#${item}`}>{item}</a>
            </li>
          ))}
          <li>
            <a
              href="/#home"
              className={`scroll-to-top mx-hide ${
                isScrolled ? "visible" : "hidden"
              }`}
            >
              Scroll to top
            </a>
          </li>
        </ul>
        <div
          className={`hamburger-menu ${isPhoneMenuOpen ? "open" : ""} hide`}
          id="toggle"
          onClick={() => togglePhoneMenu()}
        >
          <div className="bar"></div>
          {/* <div className="bar"></div> */}
          <div className="bar"></div>
        </div>
      </motion.nav>
      <nav className="app__navbar hide">
        <div className="app__navbar-logo">
          <a href="/">
            {/* <h1>
              <a href="/">LIAM MELKERSSON™ </a>
            </h1> */}
            <img
              src={images.helmetgif}
              alt="gif if a helmet"
              className="helmet-gif-mobile"
            />
          </a>
          {/* <img
            src={images.helmetgif}
            alt="gif if a helmet"
            className="helmet-gif mx-hide"
          /> */}
        </div>
        {/* <div className="app__navbar-text-1 p-text mx-hide">
          <p>Designer—Developer—Creative</p>
        </div>
        <div className="app__navbar-text-2 p-text mx-hide">
          <p>Based in JKPG, Sweden.</p>
        </div> */}
        <ul className="app__navbar-links">
          {["projects", "about", "contact"].map((item) => (
            <li className="app__navbar-link mx-hide" key={`link-${item}`}>
              <div />
              <a href={`/#${item}`}>{item}</a>
            </li>
          ))}
          <li>
            <a
              href="/#home"
              className={`scroll-to-top mx-hide ${
                isScrolled ? "visible" : "hidden"
              }`}
            >
              Scroll to top
            </a>
          </li>
        </ul>
        <div
          className={`hamburger-menu ${isPhoneMenuOpen ? "open" : ""} hide`}
          id="toggle"
          onClick={() => togglePhoneMenu()}
        >
          <div className="bar"></div>
          {/* <div className="bar"></div> */}
          <div className="bar"></div>
        </div>
      </nav>

      {isPhoneMenuOpen && (
        <PhoneMenu isOpen={isPhoneMenuOpen} onClose={togglePhoneMenu} />
      )}
    </>
  );
};

export default Navbar;
