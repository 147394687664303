import React, { useEffect, useState, useRef } from "react";
import "./About.scss";
import { images } from "../../constants";

const About = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const aboutSectionRef = useRef();

  const handleScroll = () => {
    const aboutSectionTop = aboutSectionRef.current.offsetTop;
    const aboutSectionBottom =
      aboutSectionTop + aboutSectionRef.current.clientHeight + 2;

    const scrollY = window.scrollY;
    if (scrollY >= aboutSectionTop && scrollY <= aboutSectionBottom) {
      setScrollPosition(scrollY - aboutSectionTop);
      setIsVisible(true);
    } else {
      setScrollPosition(0);
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="app__about" id="about" ref={aboutSectionRef}>
      <h1 className="abt-text hide">
        ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ I STRIVE TO BE THE ROUND PEG IN A
        SQUARE-HOLE WORLD. <br />
        BLENDING MODERN AESTHETICS WITH FRONT-END EXPERTISE, I EMBRACE THE
        CREATIVE EDGE THAT SETS ME APART.
      </h1>
      <h1 className="abt-text mx-hide">
        ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ I STRIVE TO BE THE ROUND PEG IN A
        SQUARE-HOLE WORLD. BLENDING MODERN AESTHETICS WITH FRONT-END EXPERTISE,
        I EMBRACE THE CREATIVE EDGE THAT SETS ME APART.
      </h1>
      <div className="mx-spotify-container hide">
        {/* <iframe
          src="https://open.spotify.com/embed/playlist/7v1XD7jzjyddb2UnHyeprM?utm_source=generator"
          width="100%"
          height="352"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
          className="hide mx-spotify"
        ></iframe> */}

        <a
          className="spotify-button hide"
          href="https://open.spotify.com/playlist/7v1XD7jzjyddb2UnHyeprM?si=c37a492038574873"
        >
          <p className="hide">Spotify Playlist 🥐</p>
        </a>
      </div>

      <img
        src={images.me}
        alt="me myself and i"
        className="img-me mx-hide "
        style={{
          marginLeft: `${scrollPosition / 15}vw`,
          opacity: isVisible ? 1 : 0,
          transition: "opacity 0.5s ease",
        }}
      />
      <div className="abt-p-container">
        <p className="about-p about-mx-text mx-hide">
          In my freetime I'm all about dreaming to Frank Ocean tunes 🎧
          supporting West Ham United 🫧 watching keyboard videos ⌨️ and being a
          plant dad 🪴
          <br />
          <br />
          Inspired by creatives like Virgil Abloh and Rick Rubin, I seek to put
          my mark on both development, design and other creative areas 🌱
        </p>
        <p className="phone-about-p about-mx-text hide">
          In my freetime I'm all about dreaming to Frank Ocean tunes 🎧
          supporting West Ham United 🫧 watching keyboard videos ⌨️ and being a
          plant dad 🪴
          <br />
          <br />
          Inspired by creatives like Virgil Abloh and Rick Rubin, I seek to put
          my mark on both development, design and other creative areas 🌱
        </p>
        <iframe
          title="Spotify playlist - Create 2.0"
          src="https://open.spotify.com/embed/playlist/7v1XD7jzjyddb2UnHyeprM?utm_source=generator"
          width="358"
          height="358"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
          className="mx-hide"
        ></iframe>
      </div>
    </section>
  );
};

export default About;
