// PhoneMenu.js
import React, { useEffect } from "react";
import "./PhoneMenu.scss";

const PhoneMenu = ({ isOpen, onClose }) => {
  useEffect(() => {
    const handleLinkClick = () => {
      // Call the onClose function to toggle off the PhoneMenu
      onClose();
    };

    // Attach the handleLinkClick function to all anchor tags inside PhoneMenu
    const links = document.querySelectorAll(".nav-link a");
    links.forEach((link) => {
      link.addEventListener("click", handleLinkClick);
    });

    // Cleanup event listeners when the component unmounts
    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", handleLinkClick);
      });
    };
  }, [onClose]);

  const style = {
    display: isOpen ? "block" : "none",
  };

  return (
    <div className={`PhoneMenu hide`} style={style}>
      <div className="content-overlay">
        <div className="nav-links">
          <div className="nav-link">
            <span>01</span>
            <a href="/#projects">Projects</a>
          </div>
          <div className="nav-link">
            <span>02</span>
            <a href="/#about">About</a>
          </div>
          <div className="nav-link">
            <span>03</span>
            <a href="/#contact">Contact</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneMenu;
