import React, { useEffect } from "react";
import { preLoaderAnim } from "../../animations";
import { images } from "../../constants";

const Preloader2 = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);

  return (
    <div className="preloader">
      <img src={images.helmetgif} alt="helmet gif" className="preloader-gif" />
      {/*
      <div className="preloader-text-container mx-flex-dir">
        <span className="mx-hide">Designer</span>
        <span className="mx-hide">Developer</span>
        <span className="mx-hide">Creative</span>
      </div> */}
    </div>
  );
};

export default Preloader2;
