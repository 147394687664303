// import React, { useState, useEffect } from "react";
import React from "react";
import { motion } from "framer-motion";
import "./Banner.scss";

const Banner = () => {
  // const [currentTime, setCurrentTime] = useState(
  //   new Date().toLocaleTimeString()
  // );

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentTime(new Date().toLocaleTimeString());
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <motion.div
      whileInView={{ y: [75, 0], opacity: [0, 1] }}
      transition={{ duration: 0.65, delay: 4 }}
      className="banner"
    >
      <p className="banner-p">Version 2.0 ‎ ‎ </p>
      {/* <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, yoyo: Infinity }}
      ></motion.p> */}
      {/* <p className="banner-p"> {currentTime}</p> */}
    </motion.div>
  );
};

export default Banner;
