import React from "react";
import { Navbar, Banner } from "../../components";
import { Contact } from "../../containers";

import images from "../../constants/images";
import Preloader2 from "../../components/PreLoader2/Preloader2";

const Project01 = () => {
  return (
    <div className="app__project01">
      <Preloader2 />
      <Banner />
      <Navbar />

      <h4 className="phone-h4 h4 mx-hide">REST API: PORTFOLIO</h4>
      <div className="phone-proj-header hide">
        <h4 className="h4 hide">REST API: PORTFOLIO</h4>
      </div>

      <img
        src={images.thumbnailwdfportfolio}
        alt=""
        className="proj-thumbnail"
      />

      <p className="brief proj-text mx-hide">
        <b>BRIEF</b> Create a full stack portfolio with a database. You should
        be able to dynamically add users and projects. You should also be able
        to login as a user and as an admin to create, modify or delete users and
        projects (theres dashboard pages for this). It should also be responsive
        and have a pagination system.
      </p>
      <p className="brief phone-proj-text hide">
        <b>BRIEF</b> Create a full stack portfolio with a database. You should
        be able to dynamically add users and projects. You should also be able
        to login as a user and as an admin to create, modify or delete users and
        projects (theres dashboard pages for this). It should also be responsive
        and have a pagination system.
      </p>

      <div className="showcase-container">
        <video
          src={images.portfoliofs3}
          className="proj-media"
          autoPlay
          loop
        ></video>
        <video
          src={images.projects}
          className="proj-media"
          autoPlay
          loop
        ></video>
      </div>

      <p className="proj-text mx-hide">
        <b>REFLECTION</b> I learnt a lot during this project — all of the
        technical parts as to how the internet works with http and how servers
        and databases works together to serve the client with web pages and
        information. Overall I'm very happy with the project.
      </p>
      <p className="phone-proj-text hide">
        <b>REFLECTION</b> I learnt a lot during this project — all of the
        technical parts as to how the internet works with http and how servers
        and databases works together to serve the client with web pages and
        information. Overall I'm very happy with the project.
      </p>

      <div className="showcase-container">
        <video
          src={images.phoneportfolio}
          className="proj-media"
          autoPlay
          loop
          typeof="video/mov"
        ></video>
      </div>

      <div className="last-text-box">
        <p className="proj-text mx-hide">
          <b>TECH STACK</b> HTML, CSS, JavaScript, Node.js, Express.js,
          Tailwind.css, Handlebars, SQLite3.
        </p>
        <p className="phone-proj-text hide">
          <b>TECH STACK</b> HTML, CSS, JavaScript, Node.js, Express.js,
          Tailwind.css, Handlebars, SQLite3.
        </p>

        <div className="proj-links">
          <div className="proj-link">
            <a href="https://dribbble.com/shots/23013218-REST-API-Full-Stack-Portfolio">
              Dribble
            </a>{" "}
            <span className="mx-hide">01</span>
          </div>
          <div className="proj-link">
            <a href="https://github.com/liammelkersson/wdf-portfolio">GITHUB</a>{" "}
            <span className="mx-hide">02</span>
          </div>
          {/* <div className="proj-link">
            <a href="">PROJECT REPORT</a> <span className="mx-hide">03</span>
          </div> */}
        </div>
      </div>

      <hr />

      <footer className="proj-footer">
        <a href="/#projects" className="go-back-btn">
          ← Go back
        </a>
        <p className="proj-footer-text">Liam Melkersson ©2024</p>
      </footer>

      <Contact />
    </div>
  );
};

export default Project01;
