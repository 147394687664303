import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import { About, Contact, Home, Projects } from "./container";
// import { Navbar } from "./components";
import "./App.scss";
import {
  Landing,
  Project01,
  Project02,
  Project03,
  Project04,
  Project05,
} from "./pages";

const App = () => {
  return (
    <div className="app">
      <BrowserRouter basename="/">
        <Routes>
          <Route index element={<Landing />} />
          <Route path="/home" element={<Landing />} />
          <Route path="/01" element={<Project01 />} />
          <Route path="/02" element={<Project02 />} />
          <Route path="/03" element={<Project03 />} />
          <Route path="/04" element={<Project04 />} />
          <Route path="/05" element={<Project05 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
