import React from "react";

import { About, Contact, Home, Projects } from "../../containers";
import { Navbar, Banner, PhoneMenu, MobileCover } from "../../components";
import "../../App.scss";
import Preloader from "../../components/PreLoader/Preloader";

const Landing = () => {
  return (
    <div className="app">
      {/* <MobileCover /> */}
      <Preloader />
      <Banner />
      <Navbar />
      <PhoneMenu />
      <Home />
      <Projects />
      <About />
      <Contact />
    </div>
  );
};

export default Landing;
