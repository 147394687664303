//GENERAL
import projectspreview from "../assets/projects-preview.png";
import helmetgif from "../assets/helmet.gif";
import cv from "../assets/CV.pdf";
import me from "../assets/me2.png";

//PROJECT 1
import steamthumbnail from "../assets/p1/steam-thumbnail.png";
import steam from "../assets/p1/steam.png";
import profile from "../assets/p1/profile.png";
import store from "../assets/p1/store.png";
import library from "../assets/p1/library.png";
import games from "../assets/p1/games.png";
import stammy from "../assets/p1/stammy.mov";
import stammy1 from "../assets/p1/stammy1.png";
import stammy2 from "../assets/p1/stammy2.png";

//PROJECT 2
import thumbnail from "../assets/p2/thumbnail.png";
import door from "../assets/p2/door.png";
import posts from "../assets/p2/posts.png";
import recordvid from "../assets/p2/recordvid.mov";

//PROJECT 3
import thumbnailwdfportfolio from "../assets/p3/thumbnail-wdf-portfolio.png";
import restapiportfolio from "../assets/p3/restapiportfolio.png";
import phoneportfolio from "../assets/p3/phoneportfolio.mov";
import projects from "../assets/p3/projects.mov";
import portfoliofs3 from "../assets/p3/portfoliofs3.mov";

//PROJECT 4
import soundscape from "../assets/p4/soundscape.png";
import frankocean from "../assets/p4/frankocean.png";
import virgilabloh from "../assets/p4/virgilabloh.png";
import pinkmatter from "../assets/p4/pinkmatter.png";
import andre3000 from "../assets/p4/andre3000.png";

//PROJECT 5
import junya from "../assets/p5/junya.png";
import logotype from "../assets/p5/logotype.png";
import typography from "../assets/p5/typography.png";
import visualidentity from "../assets/p5/visualidentity.png";
import skin from "../assets/p5/skin.jpg";

export default {
  //general
  projectspreview,
  helmetgif,
  cv,
  me,
  //project 1
  steamthumbnail,
  steam,
  profile,
  store,
  library,
  games,
  stammy,
  stammy1,
  stammy2,
  //project 2
  thumbnail,
  door,
  posts,
  recordvid,
  //project 3
  thumbnailwdfportfolio,
  restapiportfolio,
  phoneportfolio,
  projects,
  portfoliofs3,
  //project 4
  soundscape,
  frankocean,
  andre3000,
  virgilabloh,
  pinkmatter,
  //project 5
  junya,
  logotype,
  typography,
  visualidentity,
  skin,
};
